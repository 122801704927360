const TR = {
  currentLanguage: "Turkish",
  languageCode: "TR",
  Navigation: {
    title: "AKTIP KARİYER AKADEMİ",
    company: "KURUMSAL",
    about: "HAKKIMIZDA",
    services: "HİZMETLER",
    gallery: "GALERİ",
    contact: "İLETIŞIM",
  },
  Header: {
    title: "AKTIP KARİYER AKADEMİ",
    paragraph:
      "Bilimsel rehberlik ve uzman eğitimi ile çalışanları güçlendiriyor, ideal kariyer yolları ve kurumsal büyüme sağlıyoruz.",
    learnMore: "DAHA FAZLASINI KEŞFET",
  },
  About: {
    title: "HAKKIMIZDA",
    whyUs: "Neden bizi seçmelisiniz?",
    iskur:
      "Aktıp Bartın Teknoloji Geliştirme ve Danışmanlık A.Ş. Türkiye İş Kurumu’nun 22.04.2024 tarihli 1682 nolu lisans belgesine sahip özel istihdam bürosudur. 4904 sayılı Türkiye İş Kurumu Kanunu gereğince iş arayanlardan ücret alınması yasaktır. Türkiye İş Kurumu Bartın İl Müdürlüğü telefon no  (0378) 227 38 60",
    paragraph:
      "Aktip Kariyer Akademisi, çalışanların yeteneklerini ve ideal kariyerlerini bilimsel yöntemlerle keşfetmelerine yardımcı olur. Odak noktamız kariyer gelişimini, performansı izlemeyi ve yüksek eğitimli, teknolojiye meraklı bir iş gücünü sürdürmeyi içerir. 6698 Sayılı Kişisel Verilerin Korunması Kanununa uyarak bilgi güvenliğini ön planda tutuyoruz.",
    Why: [
      "Uzman Eğitimi",
      "Nitelikli İşgücü",
      "Performans İzleme",
      "Bilimsel Kariyer Rehberi",
    ],
    Why2: [
      "Veri güvenliği",
      "Yapılandırılmış yaklaşım",
      "Yenilikçi Eğitim Programları",
      "Sürdürülebilir Kariyer Vizyonu",
    ],
  },
  Gallery: {
    title: "GALERİ",
    paragraph:
      "Aktip Kariyer Akademisi'nin canlı öğrenme ortamını görmek için Galerimizi keşfedin. Modern eğitim tesislerimizi, ilgi çekici atölyelerimizi ve uzman liderliğindeki seminerlerimizi keşfedin",
    content: [
      "img/aktip/img0.jpg",
      "img/aktip/img1.jpg",
      "img/aktip/img2.jpg",
      "img/aktip/img3.jpg",
    ],
  },

  Services: {
    title: "HİZMETLER",
    paragraph:
      "Aktip Kariyer Akademisi'nde, kariyer gelişimini ve kurumsal büyümeyi artırmak için tasarlanmış kapsamlı hizmet yelpazesiyle bireyleri ve kuruluşları güçlendirmeye kendimizi adadık. Tekliflerimiz, iş arayanların ve işverenlerin farklı ihtiyaçlarını karşılayacak şekilde tasarlanmış olup, bilimsel yöntemler ve uzman rehberliği aracılığıyla mümkün olan en iyi sonuçları sağlar.",
    content: [
      {
        icon: "fa fa-user",
        name: "Seçme Yerleştirme",
        text: "Alanında uzman ve deneyimli ekip arkadaşlarımız ve en son teknoloji yazılım programları ile seçme – yerleştirme işlemlerini gerçekleştiriyoruz.",
      },
      {
        icon: "fa fa-line-chart",
        name: "Ölçme Değerlendirme",
        text: "İnsan kaynaklarının çalışma sırasındaki performanslarının geçerli ve güncel yöntemlerle ölçerek, firma bazında en faydalı olacak şekilde değerlendiriyoruz. ",
      },
      {
        icon: "fa fa-book",
        name: "Mesleki Eğitim",
        text: "İşgücüne hazırlanmak için akademik uzmanlığa sahip profesyonellerden derinlemesine eğitim alın.",
      },
      {
        icon: "fa fa-lightbulb-o",
        name: "Kariyer Rehberliği",
        text: "En uygun kariyer yollarını seçmek ve ideal kariyer planları oluşturmak için bilimsel yöntemlerden yararlanın.",
      },
      {
        icon: "fa fa-rocket",
        name: "Kariyer Gelişimi",
        text: "Sürdürülebilir kariyer vizyonlarını ve çalışanların ilerlemesini teşvik eden hizmetlerle kurumsal büyümeyi destekleyin.",
      },
      {
        icon: "fa fa-pie-chart",
        name: "Bordrolama",
        text: "Firmaların nitelikli eleman temininde dış kaynak yolu ile çözümlerine katkı sağlayarak, tüm bordrolama işlemlerinin takibini yapıyoruz.",
      },
    ],
  },
  Testimonials: [
    {
      img: "img/testimonials/01.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "John Doe",
    },
    {
      img: "img/testimonials/02.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "Johnathan Doe",
    },
    {
      img: "img/testimonials/03.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "John Doe",
    },
    {
      img: "img/testimonials/04.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "Johnathan Doe",
    },
    {
      img: "img/testimonials/05.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "John Doe",
    },
    {
      img: "img/testimonials/06.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "Johnathan Doe",
    },
  ],
  Team: [
    {
      img: "img/team/01.jpg",
      name: "John Doe",
      job: "Director",
    },
    {
      img: "img/team/02.jpg",
      name: "Mike Doe",
      job: "Senior Designer",
    },
    {
      img: "img/team/03.jpg",
      name: "Jane Doe",
      job: "Senior Designer",
    },
    {
      img: "img/team/04.jpg",
      name: "Karen Doe",
      job: "Project Manager",
    },
  ],
  Contact: {
    title: "İletişim",
    paragraph:
      "Lütfen aşağıdaki formu doldurun, size en kısa sürede geri dönüş yapacağız.",
    addressName: "Adres",
    phoneName: "Telefon",
    emailName: "Email",
    contactInfo: "İletişim bilgileri",
    address: "Köyortası Mah., İskele Cd. NO:2011 Merkez/Bartın ",
    phone: "+90 533 027 10 74",
    email: "kariyer@aktip.com.tr",
    instagram: "https://www.instagram.com/aktipkariyerakademi/",
    twitter: "twitter.com",
    youtube: "youtube.com",
    createdBy: (
      <p>
        &copy; 2024 AKTIP TEKNO Tüm Hakları Saklıdır. 
        {/* <a href="https://www.linkedin.com/in/linkedhater2/" rel="nofollow">
          {" "}
          A_Sulaiman
        </a> */}
      </p>
    ),
  },
  Features: {
    title: "KURUMSAL",
    content: [
      {
        icon: "fa fa-eye",
        title: "Vizyon",
        text: "Faydaya dayalı ve sonuç odaklı olarak, iş arayanlar ve firmalardan başlanarak, sonuçta tüm bir bölgenin kalkınmasını sağlayarak mutlu bir Bartın ili oluşturmaktadır.",
      },
      {
        icon: "fa fa-flag",
        title: "Misyon",
        text: "En değerli zenginliğimiz olan insan kaynaklarından en iyi şekilde faydalanmak amacıyla çalışanları profesyonelleştirmek ve kuruluşları kalite yönetim sistemi uygulayarak kurumsallaştırmaktır.",
      },
      {
        icon: "fa fa-crosshairs",
        title: "Hedefler",
        text: "İşveren ve adaylar için doğru eşleştirmeler yaparak, eğitimli, donanımlı ve yüksek teknolojiye haiz kalifiye işgücü temini hedeflemektedir.",
      },
    ],
  },
};
const EN = {
  currentLanguage: "English",
  languageCode: "EN",
  Navigation: {
    title: "AKTIP CAREER ACADEMY",
    company: "CORPORATE",
    about: "ABOUT US",
    services: "Services",
    gallery: "Gallery",
    contact: "Contact",
  },
  Header: {
    title: "AKTIP CAREER ACADEMY",
    paragraph:
      "Empowering employees with scientific guidance and expert training, we provide ideal career paths and corporate growth.",
    learnMore: "LEARN MORE",
  },
  About: {
    title: "ABOUT US",
    whyUs: "Why choose us?",
    iskur:
      "AKTIP BARTIN TECHNOLOGY DEVELOPMENT AND CONSULTANCY INC. This certificate has been issued by the Turkish Employment Agency pursuant to the decision dated 18/04/2024 and numbered 15826674, to operate as a private employment office between 22/04/2024 and 21/04/2027. Bartın Provincial Directorate of Labor and Employment Agency (0378) 227 38 60.",
    paragraph:
      "Aktip Career Academy helps employees discover their talents and ideal careers through scientific methods. Our focus includes career development, performance monitoring, and maintaining a highly educated, tech-savvy workforce. We prioritize information security in compliance with the Personal Data Protection Law No. 6698.",
    Why: [
      "Expert Training",
      "Qualified Workforce",
      "Performance Monitoring",
      "Scientific Career Guidance",
    ],
    Why2: [
      "Data Security",
      "Structured Approach",
      "Innovative Training Programs",
      "Sustainable Career Vision",
    ],
  },
  Gallery: {
    title: "Gallery",
    paragraph:
      "Explore our Gallery to see the vibrant learning environment of Aktip Career Academy. Discover our modern training facilities, engaging workshops, and expert-led seminars.",
    content: [
      "img/aktip/img0.jpg",
      "img/aktip/img1.jpg",
      "img/aktip/img2.jpg",
      "img/aktip/img3.jpg",
    ],
  },
  Services: {
    title: "SERVICES",
    paragraph:
      "At Aktip Career Academy, we are dedicated to empowering individuals and organizations with a comprehensive range of services designed to enhance career development and corporate growth. Our offerings are tailored to meet the diverse needs of job seekers and employers, ensuring the best possible outcomes through scientific methods and expert guidance.",
    content: [
      {
        icon: "fa fa-user",
        name: "Recruitment",
        text: "We conduct recruitment processes with our expert and experienced team members and the latest technology software programs.",
      },
      {
        icon: "fa fa-line-chart",
        name: "Assessment",
        text: "We measure the performance of human resources during work with valid and up-to-date methods and evaluate it in the most beneficial way for the company.",
      },
      {
        icon: "fa fa-book",
        name: "Vocational Training",
        text: "Receive in-depth training from professionals with academic expertise to prepare for the workforce.",
      },
      {
        icon: "fa fa-lightbulb-o",
        name: "Career Guidance",
        text: "Utilize scientific methods to select the most suitable career paths and create ideal career plans.",
      },
      {
        icon: "fa fa-rocket",
        name: "Career Development",
        text: "Support corporate growth with services that promote sustainable career visions and employee advancement.",
      },
      {
        icon: "fa fa-pie-chart",
        name: "Payroll",
        text: "We contribute to solutions through outsourcing in providing qualified personnel for companies and follow all payroll processes.",
      },
    ],
  },
  Testimonials: [
    {
      img: "img/testimonials/01.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sed commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "John Doe",
    },
    {
      img: "img/testimonials/02.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sed commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "Johnathan Doe",
    },
    {
      img: "img/testimonials/03.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sed commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "John Doe",
    },
    {
      img: "img/testimonials/04.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sed commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "Johnathan Doe",
    },
    {
      img: "img/testimonials/05.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sed commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "John Doe",
    },
    {
      img: "img/testimonials/06.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sed commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "Johnathan Doe",
    },
  ],
  Team: [
    {
      img: "img/team/01.jpg",
      name: "John Doe",
      job: "Director",
    },
    {
      img: "img/team/02.jpg",
      name: "Mike Doe",
      job: "Senior Designer",
    },
    {
      img: "img/team/03.jpg",
      name: "Jane Doe",
      job: "Senior Designer",
    },
    {
      img: "img/team/04.jpg",
      name: "Karen Doe",
      job: "Project Manager",
    },
  ],
  Contact: {
    title: "Contact",
    paragraph:
      "Please fill out the form below, and we will get back to you as soon as possible.",
    addressName: "Address",
    phoneName: "Phone",
    emailName: "Email",
    contactInfo: "Contact Information",
    address: "Köyortası Mah., İskele Cd. NO:2011 Merkez/Bartın",
    phone: "+90 533 027 10 74",
    email: "kariyer@aktip.com.tr",
    instagram: "https://www.instagram.com/aktipkariyerakademi/",
    twitter: "twitter.com",
    youtube: "youtube.com",
    createdBy: (
      <p>
        &copy; 2024 AKTIP TEKNO All Rights Reserved.
        {/* <a href="https://www.linkedin.com/in/linkedhater2/" rel="nofollow">
          {" "}
          A_Sulaiman
        </a> */}
      </p>
    ),
  },
  Features: {
    title: "CORPORATE",
    content: [
      {
        icon: "fa fa-eye",
        title: "Vision",
        text: "To create a happy Bartın province by ensuring the development of an entire region, starting from job seekers and companies, based on benefit and results.",
      },
      {
        icon: "fa fa-flag",
        title: "Mission",
        text: "To professionalize employees and institutionalize organizations by implementing a quality management system to make the best use of our most valuable resource, human resources.",
      },
      {
        icon: "fa fa-crosshairs",
        title: "Goals",
        text: "To provide trained, equipped, and high-tech qualified workforce by making the right matches for employers and candidates.",
      },
    ],
  },
};

export const langs = {
  EN,
  TR,
};
